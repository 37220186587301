@import '../../styles/variables';

.container {
  width: 100%;
  height: 65vh;
  max-height: 800px;
  order: 2;

  @include lg {
    height: 800px;
    width: 50%;
    float: right;
  }
}

.map {
  width: 100%;
  height: 100%;
}

:global(.sponsorat-slim) {
  .container {
    width: 100%;
  }
}
