.container {
  .slider {
    position: relative;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .currentslide {
      position: absolute;
      opacity: 0;
      width: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: auto;
        aspect-ratio: 2/1;
      }
    }
    .currentslideactive {
      opacity: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        aspect-ratio: 2/1;
      }
    }
  }
  .currentimagenav {
    display: none;
    position: absolute;
    bottom: 0.05rem;

    button {
      background-color: transparent;
      border: 1px solid white;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      padding: 0;
      z-index: 20;

      &.active {
        background-color: white;
      }
    }
  }
  .nav {
    button {
      background-color: transparent;
      border: none;
    }
    .left {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 20;
      font-size: 1rem;
      color: blue;
      z-index: 5;
      cursor: pointer;
      user-select: none;
    }
    .right {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 2rem;
      color: blue;
      z-index: 5;
      cursor: pointer;
      user-select: none;
    }
  }
}
