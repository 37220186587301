@import '../../styles/variables';

.container {
  padding-right: 0.3rem;
  order: 3;
  width: 100%;
  overflow: hidden;
  table {
    width: 100%;
    border-spacing: 0 1rem;
    position: relative;

    thead {
      display: none;

      tr {
        font-family: 'Eika-semibold';
        position: relative;
        background-color: transparent;

        th::after {
          content: '';
          position: absolute;
          height: 1px;
          width: 100%;
          background-color: #64b1ba;
          left: 0;
          bottom: 0;
        }
      }
    }
    tbody {
      position: relative;

      tr {
        font-family: 'Eika-semibold';
        position: relative;
        background-color: #f2f0ef;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        min-height: 134px;
        height: 100%;
        max-width: calc(100vw - 2rem);

        &.activeRow {
          position: relative;
          &::after {
            position: absolute;
            content: '';
            border: 1px solid black;
            min-height: 134px;
            height: 100%;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 1;
          }
          td {
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;

            &:nth-child(1) {
              border-left: 1px solid transparent;
            }
            &:nth-child(3) {
              border-right: 1px solid transparent;
            }
          }
        }
        &.nobackground {
          background-color: transparent;

          &:hover {
            cursor: initial;
          }
          td:nth-child(1) {
            padding-top: 0;
            padding-left: 0;
          }
        }
        &:hover {
          cursor: pointer;
        }
        td {
          display: inline-block;
          position: relative;
          padding: 0 1rem;
          z-index: 2;
          white-space: break-spaces;
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;

          > button {
            position: absolute !important;
            height: 1px;
            width: 1px;
            overflow: hidden;
            clip: rect(1px 1px 1px 1px);
            clip: rect(1px, 1px, 1px, 1px);
          }
          &:nth-child(1) {
            padding-top: 1rem;
            font-family: 'Eika-Bold';
            border-left: 1px solid transparent;
          }
          &:nth-child(2) {
            padding-top: 0.5rem;
            margin-left: 0;
          }
          &.noresults:nth-child(3) {
            padding-right: 0;
            width: auto;

            &::after {
              display: none;
            }
          }
          &:nth-child(3) {
            padding-top: 1.5rem;
            width: auto;
            border-right: 1px solid transparent;
            padding-right: 0.5rem;
            padding-bottom: 1rem;

            &::after {
              content: 'kr';
              margin-left: 0.3rem;
            }
          }
        }
      }
    }
    tr {
      td {
        font-size: 18px;
        vertical-align: top;
        width: calc(100% - 2rem);
        text-align: left;

        &:first-of-type {
          width: calc(100% - 2rem);
        }
        &:last-of-type {
          text-align: right;
        }
      }
      th {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        font-family: 'Eika-Bold';
        width: 25%;
        background-color: transparent;
        position: relative;
        padding: 0.4rem 0;

        &:first-of-type {
          width: 50%;
          padding-left: 0.4rem;
        }
        &:last-of-type {
          text-align: right;
          padding-right: 0.4rem;
        }
      }
    }
    tfoot {
      display: none;
      background-color: black;
      width: 100%;
      border: 1px solid black;
      padding: 10px;
      color: #000;

      tr {
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
        td {
          font-family: 'Eika-semibold';
          border: none !important;
          color: white;
          padding: 0.8rem 0;

          &:first-of-type {
            padding-left: 0.5rem;
          }
          &:last-of-type {
            &::after {
              content: 'kr';
              margin-left: 0.3rem;
              padding-right: 0.5rem;
            }
          }
        }
      }
    }
  }
  @include md {

    table {
      border-spacing: 0 0;
      position: relative;
      display: table;

      thead {
        display: table-header-group;
        position: relative;

        tr {
          position: relative;
          display: table-row;
          th {
            position: relative;
            z-index: 2;
            background-color: #f2f0ef;
            padding: 0.8rem 0;
          }
        }
      }
      tfoot {
        display: table-footer-group;
        td:last-of-type {
        }
      }
      tbody {
        tr {
          background-color: transparent;
          display: table-row;
          height: auto;

          &.activeRow {
            &::after {
              display: none;
            }
            td {
              border-top: 1px solid #000;
              border-bottom: 1px solid #000;

              &:nth-child(1) {
                border-left: 1px solid #000;
              }
              &:nth-child(3) {
                border-right: 1px solid #000;
              }
            }
          }
          &.nobackground {
            background-color: transparent;

            &:hover {
              cursor: initial;
            }
            td:nth-child(1),
            td:nth-child(2),
            td:nth-child(3) {
              padding-top: 1rem;
              padding-left: 0;
              padding-bottom: 1.4rem;
            }
          }
          &:nth-child(even) {
            background-color: #f2f0ef;
          }
          &:hover {
            cursor: pointer;
          }

          td {
            display: table-cell;
            white-space: initial;
            padding: 0.8rem 0;

            &:nth-child(1) {
              font-family: 'Eika-semibold';
              padding-bottom: 0.8rem;
              font-weight: 400;
              width: 50%;
              padding-top: 0.8rem;
              padding-left: 0.4rem;
            }
            &:nth-child(2) {
              position: relative;
              left: 0;
              padding-bottom: 0.8rem;
              padding-top: 0.8rem;
              width: 25%;
            }
            &:nth-child(3) {
              padding-top: 0.8rem;
              width: 25%;
              padding-bottom: 0;
              position: relative;
            }
          }
          &:first-of-type td {
            padding-top: 0.6rem;
          }
        }
      }
    }
  }

  @include lg {
    width: calc(50% - 1rem);
  }
}

:global(.sponsorat-slim) {
  .container {
    width: 100%;
  }
}
