

// COLORS
$lightgray: #c3b3ab;
$bluegreen: #64b1ba;
$font-eika-medium: 'Eika-Medium', Arial, sans-serif;

@mixin sm {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 800px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1200px) {
    @content;
  }
}
