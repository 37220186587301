@import '../../../styles/variables';

.container {
  max-width: 400px;
  width: 100%;

  .innercontainer {
    padding: 0 12px 12px;

    > p {
      line-height: 1.6;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-bottom: 0.5rem;
      display: table;

      li {
        display: table-row;

        p {
          display: table-cell;
          margin-top: 0;
          padding-bottom: 0.4rem;

          span:first-of-type {
            font-family: 'Eika-Bold';
            margin-left: 0.3rem;
          }

          &:first-of-type {
            width: 24%;
          }
          &:nth-child(2) {
            padding-right: 0.8rem;
            text-align: right;
            width: 24%;
          }
          &:last-of-type {
            width: 52%;
          }
        }
      }
    }

    div.total {
      margin-top: 0.8rem;
      display: table;

      > div {
        display: table-row;
      }
      p {
        display: table-cell;
        margin-top: 0;
        span:first-of-type {
          margin-left: 0.3rem;
          font-family: 'Eika-Bold';
        }

        &:first-of-type {
          padding-right: 0.8rem;
          width: 24%;
        }
        &:nth-child(2) {
          padding-right: 0.5rem;
          text-align: right;
          width: 24%;
        }
        &:last-of-type {
          width: 52%;
        }
      }
    }
  }
  h2 {
    font-size: 18px;
    margin: 1rem 0;
    font-family: 'Eika-Bold';
  }
  h3 {
    font-size: 16px;
    margin-top: 1rem;
    margin-bottom: 0.2rem;
  }
  p {
    font-size: 16px;
    margin-bottom: 0.8rem;
  }
  .divider {
    display: block;
    height: 1px;
    background-color: $bluegreen;
    width: 100%;
  }

  @include lg {
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 24px;
      margin-top: 0.7rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 18px;
    }
    .innercontainer ul li p:first-of-type {
    }
  }
}
