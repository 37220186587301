@import '../../styles/variables';

.container {
  width: 100%;
  margin-bottom: 23px;
  order: 1;

  .label {
    font-family: 'Eika-semibold';
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 0;
  }
  button {
    margin: 1.5rem 1rem 0 0;
    background: white;
    color: black;
    border: 2px solid $lightgray;
    border-radius: 30px;
    padding: 10px 20px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-family: 'Eika-semibold';
    font-size: 18px;

    &:hover {
      background-color: rgb(216, 216, 216);
    }
    &.active {
      background-color: $lightgray;
    }
  }
  @include lg {
    float: left;
    width: 100%;
    margin-bottom: 3rem;
  }
}
