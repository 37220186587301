@import '../../styles/variables';

.container {
  width: fit-content;
  order: 3;
  margin-top: 0.5rem;

  .label {
    font-family: 'Eika-semibold';
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0.5rem;
  }
  > div {
    position: relative;

    select {
      width: 140px;
      height: 43px;
      z-index: 0;
      border-radius: 5px;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      font-family: 'Eika-semibold';
      background-color: transparent;
      font-size: 18px;
      color: #000;
      box-shadow: 0px 2px 3px #d3d3d3;
      margin-bottom: 4rem;
      padding-left: 0.5rem;
    }
    svg {
      position: absolute;
      top: 18px;
      right: 10px;
      pointer-events: none;
    }
  }
  @include lg {
    margin-top: 0;
  }
}
